import * as React from "react"

// import Datetime from 'react-datetime';

import { Logo } from "./Logo" 
import {Header} from './Header'
import {Footer} from './Footer'
import {ApplicationForm} from './Components/ApplicatinoForm/ApplicationForm'
import   './styles.css'

var countryList: any = ['Pakistan', 'India', 'United Arab Emirates'];

export const App = () => (
<>

  <Header/>
  <div className='container'>
  <ApplicationForm/>
  </div>
  <Footer/>
</>
)
