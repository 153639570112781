import React, { useState, useEffect, useRef } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from 'react-hook-form';

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Input,
  Grid,
  theme,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Button,
  Stack,
  HStack,
  Select,
  FormControl,
  FormLabel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
// import { Lorem } from "react-lorem-component";

import { canadaPositions, countryList, polandPositions, IjobCategoryData, IselectedJobSectorData, germanyPositions, czechPositions, maltaPositions, IjobCategoryFourData, visaTypes, embassyAppearanceCountryList } from "../../Utils/Services";
import { getAllJobCategories, getAllJobCategoriesFour, submitClientApplication } from "./ApplicationService";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { format } from "path";
import { format } from 'date-fns'

// import Moment from 'react-moment';




export const ApplicationForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [appliedCountry, setCountry] = useState("");
  const [visaType, setVisaType] = useState("");
  const [embassyCountry, setEmbassyCountry] = useState("");
  const [residenceCountry, setresidenceCountry] = useState("");
  const [schengenVisaFingerPrint, setSchengenVisaFingerPrint] = useState("NO");
  const [isLoadingSubmitButton, setIsLoadingSubmitButton] = useState(false);
  const [isLoadingAddExperienceButton, setIsLoadingAddExperienceButton] = useState(false);
  const [selectedJobSectorList, setSelectedJobSectorList] = useState<IselectedJobSectorData[]>([]);
  // const [selectedExperienceCategory, setSelectedExperienceCategory] = useState({
  //   id: '',
  //   name: '',
  // });
  const [experienceList, setExperienceList] = useState<IjobCategoryData[]>([]);
  const [experienceListCatgoryFour, setExperienceListCategoryFour] = useState<IjobCategoryFourData[]>([]);
  // const [experienceList, setExperienceList] = useState<IjobCategoryData[]>([]);
  useEffect(() => {
      getExpereinceList();
  }, []);
  const { register, handleSubmit, getValues, reset } = useForm();
  const [passportIssueDate, setPassportIssueDate] = useState(undefined);
  const [passportExpiry, setPassportExpiry] = useState(undefined);
  const [idVisaValidity, setIdVisaValidity] = useState(undefined);
  const [dateOfBirth, setDateOfBirth] = useState(undefined);
  const [filters, setFilters] = useState(undefined);
  // const [searchKeyword, setSearchKeyword] = useState("");
  const [civilStatus, setCivilStatus] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const searchKeyword = useRef(null);


  // let experienceList: any = [];
  const getExpereinceList = (filters?: any) => {
    getAllJobCategories(filters)
      .then((response: any) => {
        // experienceList = response.data;
        // if (filters) {
          
        // } else {

          setExperienceListCategoryFour([]);
          setExperienceList(response.data);
        // }


      })
      .catch((e: Error) => {
        console.log('errorrr');
        console.log(e);
      });
  };

  const getExpereinceListCategoryFour = (filters?: any) => {
    getAllJobCategoriesFour(filters)
      .then((response: any) => {
        // experienceList = response.data;
        // if (filters) {
          
        // } else {

          setExperienceList([]);
          setExperienceListCategoryFour(response.data);
        // }


      })
      .catch((e: Error) => {
        console.log('errorrr');
        console.log(e);
      });
  };
  
  // const onSubmit = data => console.log(data);
  // const searchKeywordValue = (event: any) => {
  //   const { value } = event.target;
  //   console.log('search12', event);
  //   console.log('valuevalue', value);
  //   setSearchKeyword(value);

  // }
  const searchJobSector = (event: any) => {
    // const { value } = event.target;
    let value: any = searchKeyword;
    console.log('search12', value);
    value = value.current.value;

    if (value.length > 3) {
      const filters: any = {
        'search_keyword': value,
      }
        // setFilters(filters);
        getExpereinceListCategoryFour(filters);
    } else if (value.length === 0) {
      console.log('calling all')
      const filters: any = {
        'search_keyword': '',
      }
      // setFilters(filters);
      getExpereinceList(filters);
    }
  }
  const _handleSubmit = (values: any) => {
    
    
    values.passport_issue_date = passportIssueDate;
    values.passport_expiry = passportExpiry;
    values.id_visa_validity = idVisaValidity;
    values.date_of_birth = dateOfBirth;
    console.log('formSubmission', values);
    // return;
    let formData: any = new FormData();
    console.log(formData);
    // formData = values;
    
    console.log('datesss');
    console.log(values);
    console.log(formData);
    // if (selectedJobSectorList.length === 0) {
    //   toast.error("Error: Please add at atleast one experience")
    //   return;
    // }
    // return;

    Object.keys(values).forEach((key, val) => {
      if ( 
        (key === 'client_cv'
        || key=== 'contract_scanned_image'
        || key ==='payment_receipt_image'
        || key === 'schengen_scanned_copy'
        || key === 'visa_copy'
        || key === 'residence_id_upload'
        || key === 'passport_image'
        )
        ) {
          
          
          formData.append(key, (values[key] && values[key].length > 0) ? values[key][0] : null);
          
          return
        }
        
        if ( key === 'date_of_birth'
        || key=== 'id_visa_validity'
        || key ==='passport_expiry'
      || key === 'passport_issue_date')
      {
        if (values[key] && values[key] !== undefined && values[key] !== 'undefined') {
          
          console.log('parseValue', format(values[key], "dd-MM-yyyy"));
          formData.append(key, format(values[key], "dd-MM-yyyy"));
        }
        
        return;
      }
      formData.append(key, values[key]);
      
        
        
      });
      if (selectedJobSectorList.length > 0) {
        formData.append('selected_job_sector_list', JSON.stringify(selectedJobSectorList));
      }
      console.log('finalFormData',formData);
      setIsLoadingSubmitButton(true);
      submitClientApplication(formData)
      .then((response: any) => {
        setIsLoadingSubmitButton(false);
      console.log('formSubmission0', response);
      if (response.data && response.data.error && response.data.error === 'application_already_exists')
      {

        toast.error("Error: Application Already Exists, use different phone number, email and passport number for each application")
        return;
      }
      response.data = response.data.data;
      if (response.data && response.data.id) {

        toast.success("Success: Application Submitted");
        reset();
        setSelectedJobSectorList([]);
        window.location.href = 'https://pwggroup.ae/thank-you-for-application-registration';
      } else {
        toast.error("Error: Please try again later");  
        console.log(response);

      }
    })
    .catch((e: Error) => {
      setIsLoadingSubmitButton(false);
      console.log('errorrr');
      console.log(e);
      toast.error("Error: Please try again later");
    });
  };


  const addExperienceCategory = (
                                  name: any,
                                  jobCategoryFourID: any,
                                  jobCategoryThreeID: any,
                                  jobCategoryTwoID: any,
                                  jobCategoryOneID: any,
                                  descritpion: any,
                                  exampleTitles: any,
                                  mainDuties: any,
                                  employementRequirement: any
                                  ) => {
    setIsLoadingAddExperienceButton(true);
    console.log('iddd', jobCategoryFourID);
    console.log('iddd', jobCategoryThreeID);
    console.log('iddd', jobCategoryTwoID);
    console.log('iddd', jobCategoryOneID);
    const itemToBeInseted: IselectedJobSectorData = {
      'name': name,
      'job_category_one_id': jobCategoryOneID,
      'job_category_two_id': jobCategoryTwoID,
      'job_category_three_id': jobCategoryThreeID,
      'job_category_four_id': jobCategoryFourID,
      'description': descritpion,
      'example_titles': exampleTitles,
      'main_duties': mainDuties,
      'employement_requirements': employementRequirement,
    }
    const isFound = selectedJobSectorList.some((element: any) => {
      if (element.job_category_four_id === jobCategoryFourID) {
        return true;
      }
  
      return false;
    });
    if (isFound) {
      console.log('item already exists');
      toast.error("Error: Job Sector Already Added")
    } else {

    setSelectedJobSectorList((arr: IselectedJobSectorData[]) => [...arr, itemToBeInseted]);
      console.log('secorlist', selectedJobSectorList);
      toast.success("Success: Job Sector Added");
    }
    setIsLoadingAddExperienceButton(false);
  };

  const removeExperienceCategory = (index: number) => {
    
    if (index !== -1) {
      setSelectedJobSectorList([
        ...selectedJobSectorList.slice(0, index),
        ...selectedJobSectorList.slice(index + 1)
      ]);
      toast.success("Success: Job Sector Removed");
    }
  };
  const viewExperienceCategoryDetails = (id: number) => {
    console.log('iddd', id);
    // if (index !== -1) {
    //   setSelectedJobSectorList([
    //     ...selectedJobSectorList.slice(0, index),
    //     ...selectedJobSectorList.slice(index + 1)
    //   ]);
    //   toast.success("Success: Job Sector Removed");
    // }
  };

  function onChangeCaptcha(value: any) {
    console.log("Captcha value:", value);
  }


  return (
    <div>
      <ChakraProvider theme={theme}>
        <Box fontSize="xl">
          <Heading className='applicationTitle' as="h1" size="xl">
            PWG Application Form
          </Heading>
          <Heading as="h2" size="sm" textAlign="left" mt={10} color='red'>
          IMPORTANT: Already made first payment? Kindly, fill and submit this form.
          </Heading>
          <Heading as="h2" size="sm" textAlign="left" mt={2} color='red'>
          Carefully fill and submit this form to ensure accurate visa processing.
          </Heading>
          <Heading as="h2" size="sm" textAlign="left" mt={2} color='red'>
          <Link href='https://pwggroup.ae/track-your-application/' isExternal color='blue'>Click here</Link> to track your application  72 hours of submitting the form. If unsuccessfull, kindly contact us on customerservice@pwggroup.ae
          </Heading>
          <Heading as="h2" size="md" textAlign="left" mt={10}>
            Personal Information
          </Heading>
          <form onSubmit={handleSubmit(_handleSubmit)}>
            <div className="row">
              <FormControl w={['100%', '33%']} isRequired>
                <FormLabel htmlFor='name'>First Name</FormLabel>
                <Input id='name' type='text' {...register('name')} />
              </FormControl>
              <FormControl w={['100%', '33%']}>
                <FormLabel htmlFor='middle_name'>Middle Name</FormLabel>
                <Input id='middle_name' type='text' {...register('middle_name')} />
              </FormControl>
              <FormControl w={['100%', '34%']} isRequired>
                <FormLabel htmlFor='sur_name'>Surname</FormLabel>
                <Input id='sur_name' type='text' {...register('sur_name')} />
              </FormControl>
            </div>

            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input id='email' type='text'  {...register('email')}/>
              </FormControl>
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='phone_number'>Phone Number</FormLabel>
                <Input id='phone_number' type='text' {...register('phone_number')}/>
              </FormControl>
            </div>
            <div className="row">
            <FormControl w={['100%', '33%']} isRequired>
              <FormLabel htmlFor='date_of_birth'>Date of Birth</FormLabel>
                {/* <Input id='date_of_birth' type='text' {...register('date_of_birth')}/> */}
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  id='passport_issue_date'
                  className="form-control"
                  selected={dateOfBirth}
                  // {...register('date_of_birth')}
                  onChange={(date: any) => setDateOfBirth(date)}
                  // name="passport_issue_date"
                  // {...register('passport_issue_date')}
                />
              </FormControl>
              <FormControl w={['100%', '33%']} isRequired>
                <FormLabel htmlFor='place_of_birth'>Place of Birth</FormLabel>
                <Input id='place_of_birth' type='text' {...register('place_of_birth')} />
              </FormControl>

              <FormControl w={['100%', '34%']} isRequired>
                <FormLabel htmlFor='country_of_birth'>Country of Birth</FormLabel>
                <Select id='country_of_birth' placeholder='Select an option' {...register('country_of_birth')}>
                  { 
                    countryList.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Select>
              </FormControl>
            </div>

            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='citizenship'>Citizenship</FormLabel>
                <Select id='citizenship' placeholder='Select an option'
                  {...register('citizenship')}
                  onChange={(e: any) => {
                    const citizenshipValue: any = e.target.value;
                    setCitizenship(citizenshipValue);
                  }}
                  >
                  { 
                    countryList.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='sex'>Sex</FormLabel>
                <Select id='sex' placeholder='Select an option' {...register('sex')}>
                  <option value="MALE">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                </Select>
              </FormControl>


            </div>
            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='civil_status'>Civil Status</FormLabel>
                <Select
                id='civil_status'
                placeholder='Select an option' {...register('civil_status')}
                name='civil_status'
                onChange={(e) => {
                  const civilStatusValue: any = e.target.value;
                  setCivilStatus(civilStatusValue);
                }}
                >
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Separated">Separated</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widow">Widow</option>
                  <option value="Other">Other</option>
                </Select>
              </FormControl>
              {civilStatus === "Other" && (
                  <FormControl w={['100%', '50%']} isRequired>
                    <FormLabel htmlFor='other_civil_status'>Other Civil Status</FormLabel>
                    <Input id='other_civil_status' type='text' {...register('other_civil_status')} />
                  </FormControl>
                  )}
            </div>

            <div className="row">
              <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
                Application Details
              </Heading>
              <FormControl w={['100%', '33%']} isRequired>
                <FormLabel htmlFor='applied_country'>Applied Country</FormLabel>
                <Select
                  id='applied_country'
                  placeholder='Select an option' {...register('applied_country')}
                  name="applied_country"
                  onChange={(e) => {
                    const appliedCountry = e.target.value;
                    setCountry(appliedCountry);
                  }}
                >
                  <option value="CANADA">CANADA</option>
                  <option value="CZECH">CZECH</option>
                  <option value="GERMANY">GERMANY</option>
                  <option value="MALTA">MALTA</option>
                  <option value="POLAND">POLAND</option>
                </Select>
              </FormControl>


              {appliedCountry === "POLAND" && (

                <FormControl w={['100%', '33%']} isRequired>
                  <FormLabel htmlFor='applied_position_for_poland'>Applied Poland Job</FormLabel>
                  <Select id='applied_position_for_poland' placeholder='Select an option' {...register('applied_position_for_poland')}>
                    { 
                      polandPositions.map((item) => {
                        return <option value={item}>{item}</option>
                      })
                    }
                  </Select>
                </FormControl>
              )}

              {appliedCountry === "CANADA" && (

              <FormControl w={['100%', '33%']} isRequired>
                <FormLabel htmlFor='visa_type'>Visa Type</FormLabel>
                <Select id='visa_type'
                placeholder='Select an option'
                {...register('visa_type')}
                onChange={(e) => {
                  const visaType = e.target.value;
                  setVisaType(visaType);
                }}
                >
                  { 
                    visaTypes.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Select>
              </FormControl>

              )}
              {(appliedCountry === "CANADA" && visaType === "WORK") && (

                <FormControl w={['100%', '33%']} isRequired>
                  <FormLabel htmlFor='applied_position_for_canada'>Applied Canada Job</FormLabel>
                  <Select id='applied_position_for_canada' placeholder='Select an option' {...register('applied_position_for_canada')}>
                    { 
                      canadaPositions.map((item) => {
                        return <option value={item}>{item}</option>
                      })
                    }
                  </Select>
                </FormControl>
                
              )}

              {appliedCountry === "GERMANY" && (
                <FormControl w={['100%', '33%']} isRequired>
                  <FormLabel htmlFor='applied_position_for_germany'>Applied Germany Job</FormLabel>
                  <Select id='applied_position_for_germany' placeholder='Select an option' {...register('applied_position_for_germany')}>
                    { 
                      germanyPositions.map((item) => {
                        return <option value={item}>{item}</option>
                      })
                    }
                  </Select>
                </FormControl>
              )}

              {appliedCountry === "CZECH" && (
                <FormControl w={['100%', '33%']} isRequired>
                  <FormLabel htmlFor='applied_position_for_czech'>Applied Czech Job</FormLabel>
                  <Select id='applied_position_for_czech' placeholder='Select an option' {...register('applied_position_for_czech')}>
                    { 
                      czechPositions.map((item) => {
                        return <option value={item}>{item}</option>
                      })
                    }
                  </Select>
                </FormControl>
              )}
              {appliedCountry === "MALTA" && (
                <FormControl w={['100%', '33%']} isRequired>
                  <FormLabel htmlFor='applied_position_for_malta'>Applied Malta Job</FormLabel>
                  <Select id='applied_position_for_malta' placeholder='Select an option' {...register('applied_position_for_malta')}>
                    { 
                      maltaPositions.map((item) => {
                        return <option value={item}>{item}</option>
                      })
                    }
                  </Select>
                </FormControl>
              )}
              <FormControl w={['100%', '33%']} isRequired>
                <FormLabel htmlFor='is_white_collar_job'>Are You Applying For White Collar Job?</FormLabel>
                <Select id='is_white_collar_job' placeholder='Select an option' {...register('is_white_collar_job')}>
                  <option value="NO">NO</option>
                  <option value="YES">YES</option>
                </Select>
              </FormControl>
              {citizenship === "India" && (

              <FormControl w={['100%', '100%']} isRequired>
                <FormLabel htmlFor='visa_type'>Country For Embassy Appearance</FormLabel>
                <Select id='visa_type'
                placeholder='Select an option'
                {...register('embassy_country')}
                onChange={(e) => {
                  const visaType = e.target.value;
                  setEmbassyCountry(visaType);
                }}
                >
                  { 
                    embassyAppearanceCountryList.map((item) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </Select>
              </FormControl>
              )}
            </div>

            <div className="row">
              <FormControl isRequired>
                <FormLabel htmlFor='client_cv'>Upload Your CV</FormLabel>
                <Input id='client_cv' className="form-control" type='file' {...register('client_cv')} />
              </FormControl>
            </div>
            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='sales_agent_name_by_client'>Your Agent Name</FormLabel>
                <Input id='sales_agent_name_by_client' type='text' {...register('sales_agent_name_by_client')} />
              </FormControl>
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='sales_agent_phone_number_by_client'>Your Agent Phone Number</FormLabel>
                <Input id='sales_agent_phone_number_by_client' type='text' {...register('sales_agent_phone_number_by_client')} />
              </FormControl>

            </div>

            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Home Country Details
            </Heading>

            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='passport_number'>Passport Number</FormLabel>
                <Input id='passport_number' type='text' {...register('passport_number')} />
              </FormControl>
            </div>
            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='passport_issue_date'>Passport Date Of Issue</FormLabel>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  id='passport_issue_date'
                  className="form-control"
                  selected={passportIssueDate}
                  onChange={(date: any) => setPassportIssueDate(date)}
                  // name="passport_issue_date"
                  // {...register('passport_issue_date')}
                />
              </FormControl>

              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='passport_expiry'>Passport Date Of Expiry</FormLabel>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  id='passport_expiry'
                  className="form-control"
                  selected={passportExpiry}
                  onChange={(date: any) => setPassportExpiry(date)}
                  // onChange={(date) => setStartDate(date)}
                  // name="passport_expiry"
                  // {...register('passport_expiry')}
                />
              </FormControl>

              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='passport_issued_by'>Issued By (Authoriy that issued the passport)</FormLabel>
                <Input id='passport_issued_by' type='text' {...register('passport_issued_by')} />

              </FormControl>
              <div className="row">
              <FormControl isRequired>
                <FormLabel htmlFor='passport_image'>Upload Passport Copy</FormLabel>
                <Input id='passport_image' className="form-control" type='file' {...register('passport_image')} />
              </FormControl>
            </div>
            </div>

            <div className="row">
              <FormControl w={['100%', '25%']} isRequired>
                  <FormLabel htmlFor='home_country'>Home Country</FormLabel>
                  <Select id='home_country' placeholder='Select an option' {...register('country')}>
                    { 
                      countryList.map((item) => {
                        return <option value={item}>{item}</option>
                      })
                    }
                  </Select>
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='state'>State/Province</FormLabel>
                <Input id='state' type='text' {...register('state')} />
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='city'>City</FormLabel>
                <Input id='city' type='text' {...register('city')} />
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='postal_code'>Postal Code</FormLabel>
                <Input id='postal_code' type='text' {...register('postal_code')} />
              </FormControl>
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='address_line_1'>Address (Street And Number) Line 1</FormLabel>
                <Input id='address_line_1' type='text' {...register('address_line_1')} />
              </FormControl>
              <FormControl w={['100%', '50%']}>
                <FormLabel htmlFor='address_line_2'>Address (Street And Number) Line 2</FormLabel>
                <Input id='address_line_2' type='text' {...register('address_line_2')} />
              </FormControl>
        
            </div>
            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Current Residency and Work Details
            </Heading>
            <div className="row">
              <FormControl w="100%" isRequired>

              <FormLabel htmlFor='country_of_residence'>From which country are you applying? / In which country are you living right now?</FormLabel>
                    <Select id='country_of_residence' placeholder='Select an option' {...register('country_of_residence')}
                    onChange={(e) => {
                      const residenceCountry = e.target.value;
                      setresidenceCountry(residenceCountry);
                    }}>
                      { 
                        countryList.map((item) => {
                          return <option value={item}>{item}</option>
                        })
                      }
                    </Select>
              </FormControl>
            </div>
            <div className="row">
              <FormControl w={['100%', '50%']} >
                <FormLabel htmlFor='residence_mobile_number'>Current Residence Mobile Number(if have any)</FormLabel>
                <Input id='residence_mobile_number' type='text' {...register('residence_mobile_number')} />
              </FormControl>

              {residenceCountry !== "United Arab Emirates" && (
                <FormControl w={['100%', '50%']} isRequired>
                  <FormLabel htmlFor='residence_id_info'>Residence Id</FormLabel>
                  <Input id='residence_id_info' type='text' {...register('residence_id_info')} />
                </FormControl>
                  )}
              {residenceCountry === "United Arab Emirates" && (

                <FormControl w={['100%', '50%']} isRequired>
                  <FormLabel htmlFor='residence_id_info'>Emirates Id In Valid Format (e.g 784-1999-222222-0)</FormLabel>
                  <Input id='residence_id_info' type='text' {...register('residence_id_info')} />
                </FormControl>
              )}
            </div>
            <div className="row">
              <FormControl w="100%" isRequired>
                <FormLabel htmlFor='visa_expiry'>Your ID/Visa Date of Validity</FormLabel>
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  id='visa_expiry'
                  className="form-control"
                  selected={idVisaValidity}
                  onChange={(date: any) => setIdVisaValidity(date)}
                  // {...register('visa_expiry')}
                  // onChange={(date) => setStartDate(date)}
                />
              </FormControl>

              <FormControl w="100%" isRequired>
                <FormLabel htmlFor='residence_id_upload'>Upload Your Residence/Emirates ID</FormLabel>
                <Input id='residence_id_upload' className="form-control" type='file' {...register('residence_id_upload')} />
              </FormControl>
              <FormControl w="100%" >
                <FormLabel htmlFor='visa_copy'>Visa Copy</FormLabel>
                <Input id='visa_copy' type='file' className="form-control" {...register('visa_copy')} />
              </FormControl>
            </div>

            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                <FormLabel htmlFor='current_job_profession'>Profession As Per Current Job (or on Visa)</FormLabel>
                <Input id='current_job_profession' type='text' {...register('current_job_profession')} />
              </FormControl>
            </div>

            <div className="row">
            <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='work_state'>Work State/Province</FormLabel>
                <Input id='work_state' type='text' {...register('work_state')} />
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='work_city'>Work City</FormLabel>
                <Input id='work_city' type='text' {...register('work_city')} />
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='work_postal_code'>Work Place Postal Code</FormLabel>
                <Input id='work_postal_code' type='text' {...register('work_postal_code')} />
              </FormControl>
              <FormControl w={['100%', '25%']} isRequired>
                <FormLabel htmlFor='work_place_address'>Work Place Street and Number</FormLabel>
                <Input id='work_place_address' type='text' {...register('work_place_address')} />
              </FormControl>

            </div>

            <div className="row">
            <FormControl w={['100%', '33%']}>
                <FormLabel htmlFor='company_name'>Name of Company</FormLabel>
                <Input id='company_name' type='text' {...register('company_name')} />
              </FormControl>
              <FormControl w={['100%', '33%']} >
                <FormLabel htmlFor='employer_phone_number'>Employer Phone Number</FormLabel>
                <Input id='employer_phone_number' type='text' {...register('employer_phone_number')} />
              </FormControl>
              <FormControl w={['100%', '33%']} >
                <FormLabel htmlFor='employer_email'>Email of the employer</FormLabel>
                <Input id='employer_email' type='text' {...register('employer_email')} />
              </FormControl>
            </div>
            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Schengen Details
            </Heading>

            <div className="row">
              <FormControl w="100%" isRequired>
                  <FormLabel htmlFor='is_schengen_visa_issued_last_five_year'>Schengen Or National Visa Issued During Last 5 Years</FormLabel>
                  <Select id='is_schengen_visa_issued_last_five_year'
                  placeholder='Select an option' {...register('is_schengen_visa_issued_last_five_year')}
                  onChange={(e) => {
                    setSchengenVisaFingerPrint(e.target.value);
                  }}
                  >
                    <option value="NO">NO</option>
                    <option value="YES">YES</option>
                  </Select>
                </FormControl>
                {schengenVisaFingerPrint === "YES" && (
               
                  <FormControl w={['100%', '50%']}>
                    <FormLabel htmlFor='schengen_scanned_copy'>Image of Schengen Or National Visa Issued During Last 5 Years</FormLabel>
                    <Input id='schengen_scanned_copy' type='file' className="form-control" {...register('schengen_scanned_copy')} />
                  </FormControl>
                  )}
                <FormControl w="100%" isRequired>
                  <FormLabel htmlFor='is_finger_print_collected_for_Schengen_visa'>Fingerprints Collected Previously For The Purpose Of Applying For Schengen Visa 
                  </FormLabel>
                  <Select id='is_finger_print_collected_for_Schengen_visa' placeholder='Select an option' {...register('is_finger_print_collected_for_Schengen_visa')}>
                    <option value="NO">NO</option>
                    <option value="YES">YES</option>
                  </Select>
                </FormControl>
            </div>

            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Payment Details
            </Heading>
            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                    <FormLabel htmlFor='payment_receipt_image'>Payment Receipt Scanned Copy</FormLabel>
                    <Input id='payment_receipt_image' type='file' className="form-control" {...register('payment_receipt_image')} />
                  </FormControl>
            </div>

            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Contract Details
            </Heading>
            <div className="row">
              <FormControl w={['100%', '50%']} isRequired>
                    <FormLabel htmlFor='contract_scanned_image'>Contract Scanned Copy</FormLabel>
                    <Input id='contract_scanned_image' type='file' className="form-control" {...register('contract_scanned_image')} />
                  </FormControl>
            </div>
            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Coupon Details
            </Heading>
            <div className="row">
              <FormControl w="100%">
                    <FormLabel htmlFor='coupon_code'>Coupon Code (if you have any)</FormLabel>
                    <Input id='coupon_code' type='text' {...register('coupon_code')} />
                  </FormControl>
            </div>


            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Referrer Details
            </Heading>
            <div className="row">
              <FormControl w={['100%', '50%']}>
                    <FormLabel htmlFor='referrer_name'>Referrer Name (if you have any)</FormLabel>
                    <Input id='referrer_name' type='text' {...register('referrer_name')} />
                  </FormControl>
                  <FormControl w={['100%', '50%']}>
                    <FormLabel htmlFor='referrer_passport_number'>Referrer Passport Number (if you have any referrer)</FormLabel>
                    <Input id='referrer_passport_number' type='text' {...register('referrer_passport_number')} />
                  </FormControl>
            </div>
            <div className="col-md-6" id=""></div>

              <Text color='red'  mb={10} mt={10}>
                Please add your experience very carefully, and add multiple experiences if you have worked in more than one job sector
                </Text>
            <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Experience Details 
            </Heading>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Job Sector </Th>
                    <Th isNumeric>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                { 
                  selectedJobSectorList.map((item: any, index) => {

                    return (
                    <Tr>
                      <Td fontSize='sm'>{item.name} 
                        <Button 
                          colorScheme='blue' size='sm' mb={4} mt={4} ml={[0,4]}
                          onClick={onOpen}
                          >View Details
                        </Button>
                        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>Job Sector Details</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody whiteSpace={'pre-line'}>
                              <Heading as='h4'  size='md' mb={4} mt={4}>Description</Heading>
                              <Text fontSize='sm'>{item.description}</Text>

                              <Heading as='h4'  size='md' mb={4} mt={4}>Example Titles</Heading>
                              <Text fontSize='sm'>{item.example_titles}</Text>

                              <Heading as='h4'  size='md' mb={4} mt={4}>Main Duties</Heading>
                              <Text fontSize='sm'>{item.main_duties}</Text>

                              <Heading as='h4'  size='md' mb={4} mt={4}>Employement Requirment</Heading>
                              <Text fontSize='sm'>{item.employement_requirements}</Text>
                            </ModalBody>

                            <ModalFooter>
                              <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Close
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </Td>
                      <Td isNumeric>
                      <Button 
                            colorScheme='red' size='sm' mb={4} mt={4}
                            onClick={() => removeExperienceCategory(index)}
                            >Remove</Button>
                      </Td>
                    </Tr>
                    )
                  })
                }
                </Tbody>
              </Table>
              <Heading as="h2" size="md" textAlign="left" mb={10} mt={10}>
              Job Sectors List
            </Heading>
            <div className="row">
              <FormControl w={['70%', '30%']} mb={[5, 10]}>
                    <FormLabel htmlFor='search_keyword'>Search by Job Title</FormLabel>
                    <Input id='search_keyword' type='text' name='search_keyword'
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    // onChange={searchKeywordValue}
                    ref={searchKeyword}
                    placeholder='enter job title' />
              </FormControl>
              <FormControl w={['30%', '30%']} mb={[5, 10]}>
                    <FormLabel htmlFor='search_keyword'></FormLabel>
                    <Button 
                          colorScheme='blue' size='sm' mb={4} mt={7}
                          onClick={searchJobSector}
                          >Search
                        </Button>
              </FormControl>
            </div>
            <Accordion allowToggle allowMultiple>
              { 
                experienceList.map((jobCategoryOne: any) => {
                  // return <option value={item.id}>{item.name}</option>
                  return (
                            <AccordionItem>
                              <h2>
                                <AccordionButton>
                                  <Box flex='1' textAlign='left'>
                                    {jobCategoryOne.name}
                                    
                                  </Box>
                                  <AccordionIcon />
                                  
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                {/* description */}
                                <Accordion allowToggle allowMultiple>
                                  { 
                                    jobCategoryOne.job_category_two.map((jobCategoryTwo: any) => {
                                      // return <option value={item.id}>{item.name}</option>
                                      return (
                                                <AccordionItem>
                                                  <h2>
                                                    <AccordionButton>
                                                      <Box flex='1' textAlign='left'>
                                                        {jobCategoryTwo.name}
                                                        
                                                      </Box>
                                                      <AccordionIcon />
                                                      
                                                    </AccordionButton>
                                                  </h2>
                                                  <AccordionPanel pb={4}>
                                                    {/* description */}
                                                    <Accordion allowToggle allowMultiple>
                                                      { 
                                                        jobCategoryTwo.job_category_three.map((jobCategoryThree: any) => {
                                                          // return <option value={item.id}>{item.name}</option>
                                                          return (
                                                                    <AccordionItem>
                                                                      <h2>
                                                                        <AccordionButton>
                                                                          <Box flex='1' textAlign='left'>
                                                                            {jobCategoryThree.name}
                                                                            
                                                                          </Box>
                                                                          <AccordionIcon />
                                                                          
                                                                        </AccordionButton>
                                                                      </h2>
                                                                      <AccordionPanel pb={4}>
                                                                        {/* description */}
                                                                        <Accordion allowToggle allowMultiple>
                                                                          { 
                                                                            jobCategoryThree.job_category_four.map((jobCategoryFour: any) => {
                                                                              // return <option value={item.id}>{item.name}</option>
                                                                              return (
                                                                                        <AccordionItem>
                                                                                          <h2>
                                                                                            <AccordionButton>
                                                                                              <Box flex='1' textAlign='left'>
                                                                                                {jobCategoryFour.name}
                                                                                                
                                                                                              </Box>
                                                                                              <AccordionIcon />
                                                                                              
                                                                                            </AccordionButton>
                                                                                          </h2>
                                                                                          <AccordionPanel pb={4} whiteSpace={'pre-line'}>
                                                                                            {/* description */}

                                                                                            <Heading as='h4'  size='md' mb={4} mt={4}>Description</Heading>
                                                                                            <Text fontSize='sm'>{jobCategoryFour.description}</Text>

                                                                                            <Heading as='h4'  size='md' mb={4} mt={4}>Example Titles</Heading>
                                                                                            <Text fontSize='sm'>{jobCategoryFour.example_titles}</Text>

                                                                                            <Heading as='h4'  size='md' mb={4} mt={4}>Main Duties</Heading>
                                                                                            <Text fontSize='sm'>{jobCategoryFour.main_duties}</Text>

                                                                                            <Heading as='h4'  size='md' mb={4} mt={4}>Employement Requirment</Heading>
                                                                                            <Text fontSize='sm'>{jobCategoryFour.employement_requirements}</Text>
                                                                                            <Button
                                                                                            isLoading={isLoadingAddExperienceButton}
                                                                                            colorScheme='blue' mb={4} mt={4}
                                                                                            // onClick={addExperienceCategory}
                                                                                            onClick={() => addExperienceCategory(
                                                                                                                                  jobCategoryFour.name,
                                                                                                                                  jobCategoryFour.id,
                                                                                                                                  jobCategoryThree.id,
                                                                                                                                  jobCategoryTwo.id,
                                                                                                                                  jobCategoryOne.id,
                                                                                                                                  jobCategoryFour.description,
                                                                                                                                  jobCategoryFour.example_titles,
                                                                                                                                  jobCategoryFour.main_duties,
                                                                                                                                  jobCategoryFour.employement_requirements,
                                                                                                                                  )}
                                                                                            >Add Experience</Button>
                                                                                          </AccordionPanel>
                                                                                        </AccordionItem>
                                                                                        )
                                                                            })
                                                                          }
                                                                        </Accordion>
                                                                      </AccordionPanel>
                                                                    </AccordionItem>
                                                                    )
                                                        })
                                                      }
                                                    </Accordion>
                                                  </AccordionPanel>
                                                </AccordionItem>
                                                )
                                    })
                                  }
                                </Accordion>
                              </AccordionPanel>
                            </AccordionItem>
                            )
                })
              }
            </Accordion>
              {/* description */}
              <Accordion allowToggle allowMultiple>
                { 
                  experienceListCatgoryFour.map((jobCategoryFour: any) => {
                    // return <option value={item.id}>{item.name}</option>
                    return (
                              <AccordionItem>
                                <h2>
                                  <AccordionButton>
                                    <Box flex='1' textAlign='left'>
                                      {jobCategoryFour.name}
                                      
                                    </Box>
                                    <AccordionIcon />
                                    
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} whiteSpace={'pre-line'}>
                                  {/* description */}

                                  <Heading as='h4'  size='md' mb={4} mt={4}>Description</Heading>
                                  <Text fontSize='sm'>{jobCategoryFour.description}{jobCategoryFour.id}</Text>

                                  <Heading as='h4'  size='md' mb={4} mt={4}>Example Titles</Heading>
                                  <Text fontSize='sm'>{jobCategoryFour.example_titles}</Text>

                                  <Heading as='h4'  size='md' mb={4} mt={4}>Main Duties</Heading>
                                  <Text fontSize='sm'>{jobCategoryFour.main_duties}</Text>

                                  <Heading as='h4'  size='md' mb={4} mt={4}>Employement Requirment</Heading>
                                  <Text fontSize='sm'>{jobCategoryFour.employement_requirements} </Text>
                                  <Button
                                  isLoading={isLoadingAddExperienceButton}
                                  colorScheme='blue' mb={4} mt={4}
                                  // onClick={addExperienceCategory}
                                  onClick={() => addExperienceCategory(
                                                                        jobCategoryFour.name,
                                                                        jobCategoryFour.id,
                                                                        jobCategoryFour.job_category_three_id,
                                                                        jobCategoryFour.job_category_two_id,
                                                                        jobCategoryFour.job_category_one_id,
                                                                        jobCategoryFour.description,
                                                                        jobCategoryFour.example_titles,
                                                                        jobCategoryFour.main_duties,
                                                                        jobCategoryFour.employement_requirements,
                                                                        )}
                                  >Add Experience</Button>
                                </AccordionPanel>
                              </AccordionItem>
                              )
                  })
                }
              </Accordion>
              {/* <GoogleReCaptchaProvider reCaptchaKey="6Ld_SE4gAAAAAGgh2UQvJLvNs2E_nAsrvVWbNE59">
                <GoogleReCaptcha onVerify={onChangeCaptcha} />
              </GoogleReCaptchaProvider> */}

            <Button type="submit"
              isLoading={isLoadingSubmitButton}
              colorScheme="blue" mt={10} mb={20} size="lg">
              Submit
            </Button>
          </form>
          <div>
            <ToastContainer
              position="bottom-right"
              hideProgressBar={true}
              theme='colored'

            />
          </div>
        </Box>
      </ChakraProvider>
    </div>
  );
};
