import React from 'react'

export const Footer = () => {
    let footerStyle = {
     position: 'relative',
     width: '100%',
    
     
    }
    return (
 
        <footer className="bg-dark text-light py-3">
            <p className="text-center"> Copyright © 2020 PWG. All Rights Reserved</p>
        </footer>
    )
}
