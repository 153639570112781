
export const countryList: string[] = 
[
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua & Deps',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Central African Rep',
    'Chad',
    'Chile',
    'China',
    'China - Hong Kong',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo {Democratic Rep}',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland {Republic}',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea North',
    'Korea South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar, {Burma}',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'St Kitts & Nevis',
    'St Lucia',
    'Saint Vincent & the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome & Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad & Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe',
];

export const polandPositions = [
    'Accounting Assistant',
    'Android Developer',
    'Architect',
    'Baker',
    'Bartendar',
    'Bus Driver',
    'Butcher',
    'Call Center Agent',
    'Caregiver',
    'Carpenter',
    'Construction Worker',
    'Cook',
    'Data Base Administrator',
    'Data Base Analytics',
    'Electrician',
    'Executive Assistant',
    'Factory worker/ Production',
    'Financial Analyst',
    'Front Desk Agent',
    'Furniture Production',
    'Graphic Designer',
    'Housekeeping',
    'Industrial Cleaner',
    'iOS Developer',
    'Kindergarten Caregiver',
    'Kitchen Help',
    'Machine Operator',
    'Marketing Specialist',
    'Masseur',
    'Meat Production',
    'Paver',
    'Plumber',
    'Preschool English Teacher',
    'Sorter - recyclable materials',
    'Truck Driver',
    'Vegetable Packer',
    'Welder',
];


export const czechPositions = [
    'Butcher',
    'Caregiver',
    'Cook / Kitchen Help',
    'Electrician',
    'Factory worker/ Production',
    'Fruit / Vegetable Picker',
    'Grocery Store Keeper',
    'Grocery store keeper',
    'Housekeeper',
    'Truck Driver',
    'Warehouse worker',
    'Welder',
];

export const germanyPositions = [
    'Butcher',
    'Caregiver',
    'Carpenter',
    'Cook/Kitchen help',
    'Factory worker/ Production',
    'Fruit / Vegetable picker',
    'Grocery store keeper',
    'Housekeeper',
    'Nurse',
    'Warehouse worker',
    'Welder',
];

export const canadaPositions = [
    'Back of house f & b',
    'Bookkeeper',
    'Butcher',
    'Caregiver',
    'Carpenter',
    'Chef',
    'Construction Worker',
    'Construction inspector',
    'Cook',
    'Driver',
    'Electrician',
    'Equipment operator',
    'Factory Worker - Manufacturing',
    'Farm Worker',
    'Fishmonger',
    'Food Production',
    'Food Server',
    'Fruit/ Vegetable Picker',
    'Greenhouse workers',
    'Housekeeper',
    'Industrial Cleaner',
    'Kitchen Help',
    'Merchandiser',
    'Nannies',
    'Nursery',
    'Plumber',
    'Receptionist',
    'Restaurant Supervisor',
    'Study Visa',
    'Truck Driver',
    'Warehouse worker',
    'Welder',
];

export const maltaPositions = [
    'Bartender',
    'Bus Driver',
    'Carpenter',
    'Chefs',
    'Construction Worker',
    'Delivery driver',
    'Electrician',
    'Factory worker/Cleaner',
    'Factory Worker/Production',
    'Farm Worker',
    'Fruit/Vegetable picker',
    'Housekeepers',
    'Industrial cleaner',
    'Kitchen help',
    'Machine Operator',
    'Masseur',
    'Paver',
    'Plumber',
    'Truck Driver',
    'Waiters',
    'Warehouse worker',
    'Welder',
];
export const visaTypes = [
    'WORK',
    'STUDY',
];

export const embassyAppearanceCountryList = [
    'India',
    'United Arab Emirates',
];

export interface IjobCategoryData {
    
    'id': string,
    'name': string,
    'job_category_two': 
    [
        {
        'id': string,
        'name': string,
        'job_category_three': 
            [
                {
                'id': string,
                'name': string,
                'job_category_four': 
                    [
                        {
                        'id': string,
                        'name': string,
                        'description': string,
                        'example_titles': string,
                        'main_duties': string,
                        'employement_requirements': string,
                        
                        }
                    ],
                }
            ],
        }
    ],
}
export interface IjobCategoryFourData {
    
    'id': number,
    'name': string,
    'job_category_one': number,
    'job_category_two': number,
    'job_category_three': number,
    'job_category_four': number,
    'description': string,
    'example_titles': string,
    'main_duties': string,
    'employement_requirements': string,
}

export interface IselectedJobSectorData {
    
    'name': string,
    'job_category_one_id': number,
    'job_category_two_id': number,
    'job_category_three_id': number,
    'job_category_four_id': number,
    'description': string,
    'example_titles': string,
    'main_duties': string,
    'employement_requirements': string,
}