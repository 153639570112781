import http from "../../http-common";


export const getAllJobCategories = (filters: any) => {
  return http.post("/get-job-category-list", {filters});
};

export const getAllJobCategoriesFour = (filters: any) => {
  return http.post("/get-job-category-four-list", {filters});
};

export const submitClientApplication = (payload:any) => {

  console.log('payloadForm', payload);
  return http.post("/submit-client-application", payload);
};